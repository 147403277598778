import * as React from 'react';
import { navigate } from 'gatsby';

import Layout from '../containers/Layout/Layout';
import { Alert, Container, Seo } from '../components';
import { useTranslation } from '../hooks';
import ApiService from '../lib';
import { Content } from '../containers/RegistrationAction';
import { ERROR_CODES } from '../constants';
import { getDefaultPageUrl, getUrlSearchParams } from '../utils';

// http://localhost:8000/registreerimine/action?method=cancel&i=18037&h=c3d3d163062f10fa0a2f1788947e2814

const RegistrationAction = ({ pageContext: { currentPath }, location }) => {
  const { t, locale } = useTranslation();
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [registration, setRegistration] = React.useState({});
  const homeURL = getDefaultPageUrl(locale);
  const { method, i, h } = getUrlSearchParams(location.search);

  const navigateToHome = React.useCallback(
    () => navigate(homeURL, { replace: true }),
    [homeURL],
  );

  const getRegistration = React.useCallback(async () => {
    try {
      const registrationResponse = await ApiService.getRegistration({
        method,
        i,
        h,
      });

      setRegistration(registrationResponse);
    } catch (error) {
      if (error.response?.errors) {
        if (error.response.errors === ERROR_CODES.WRONG_PARAMS) {
          navigateToHome();
        } else {
          setError(t(`REGISTRATION_ACTION.ERROR.${error.response.errors}`));
        }
      } else {
        setError(t('REGISTRATION.FORM.ERROR_SERVER_SIDE'));
      }
    }
  }, [t, navigateToHome, i, h, method]);

  React.useEffect(() => {
    const IsEverythingSetFromUrl =
      !i || !h || !method || !['cancel', 'confirm'].includes(method);

    if (IsEverythingSetFromUrl) {
      return navigateToHome();
    }

    getRegistration();
  }, [navigateToHome, getRegistration, i, h, method]);

  const handleAction = async () => {
    setLoading(true);

    try {
      const data = {
        method,
        i,
        h,
        lang: locale,
      };
      await ApiService.action(data);

      setDone(true);
    } catch (error) {
      setError(t('REGISTRATION.FORM.ERROR_SERVER_SIDE'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Seo
        title={t('SEO.REGISTRATION.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container>
        {error ? (
          <Alert type="error">
            <p
              dangerouslySetInnerHTML={{
                __html: error,
              }}
            />
          </Alert>
        ) : (
          <Content
            method={method}
            homeURL={homeURL}
            registration={registration}
            handleAction={handleAction}
            loading={loading}
            done={done}
          />
        )}
      </Container>
    </Layout>
  );
};

export default RegistrationAction;
