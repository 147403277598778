import * as React from 'react';
import { format, parseISO } from 'date-fns';

import { Button, Heading, Margins } from '../../../components';
import { useTranslation } from '../../../hooks';
import * as style from './Content.module.css';

const Content = ({
  method,
  registration,
  homeURL,
  handleAction,
  loading,
  done,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style['content']}>
      {done ? (
        <Heading level="h3" visualLevel="h4">
          {t(`REGISTRATION_ACTION.${method.toUpperCase()}.DONE`)}
        </Heading>
      ) : (
        <>
          {registration.errors && (
            <p
              dangerouslySetInnerHTML={{
                __html: t(`REGISTRATION_ACTION.ERROR.${registration.errors}`),
              }}
            />
          )}
          {Object.keys(registration).length > 1 && (
            <Margins size="2x">
              <Heading level="h3" visualLevel="h4">
                {t('REGISTRATION_ACTION.HELLO')}
                {', '}
                {registration.name}!
              </Heading>

              <p>
                {t(`REGISTRATION_ACTION.${method.toUpperCase()}.ACTION`)}{' '}
                <strong>
                  {format(parseISO(registration.date), 'dd.MM.yyyy HH:mm')}
                </strong>
              </p>

              <div className={style['content__buttons']}>
                <Button variant="link" to={homeURL} disabled={loading}>
                  {t(
                    `REGISTRATION_ACTION.${method.toUpperCase()}.CANCEL_BUTTON`,
                  )}
                </Button>
                <Button onClick={handleAction} disabled={loading}>
                  {t(
                    `REGISTRATION_ACTION.${method.toUpperCase()}.ACTION_BUTTON`,
                  )}
                </Button>
              </div>
            </Margins>
          )}
        </>
      )}
    </div>
  );
};

export default Content;
